/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Overview page components
// import Header from "layouts/profile/components/Header";
import IframeResizer from "iframe-resizer-react";

const MSG = () => (
  <DashboardLayout>
    <DashboardNavbar />
    <SuiBox borderRadius="md" display="flex" justifyContent="center" alignItems="center">
      <Card style={{ width: "100vh", height: "100vh" }}>
        <IframeResizer
          log
          src="https://chat.timestope.co.kr"
          frameBorder={0}
          style={{ width: "1px", minWidth: "100%", minHeight: "100%" }}
        />
      </Card>
    </SuiBox>
    <Footer />
  </DashboardLayout>
);
export default MSG;
