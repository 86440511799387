/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Overview page components
// import Header from "layouts/profile/components/Header";
import { JSONEditor } from "react-json-editor-viewer";
import React, { useState } from "react";
import {
  Menu,
  Header,
  Button,
  Icon,
  Modal,
  Accordion,
  Message,
  Label,
  Input,
} from "semantic-ui-react";

// const MSG = () => {
//   const [rJSON, setJSON] = useState({});
//   const [search, setSearch] = useState({ isSearch: false });
//   const [original, setORG] = useState({});
//   const [recent, setRecent] = useState({ key: "TEST", value: "TEST" });
//   const [loading, setLoading] = useState(true);
//   const [open, setOpen] = useState(false);
//   const [sopen, setsOpen] = useState(false);
//   const [activeItem, setItem] = useState("KO");
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [activeIndex2, setActiveIndex2] = useState(0);
//   const [text, setText] = useState({
//     memo: "",
//     password: "",
//     nickName: "",
//     search: "",
//   });
//   const toUTF8 = (str) => Buffer.from(str, "base64").toString("utf8");

//   const toBase64 = (str) =>
//     Buffer.from(JSON.stringify(str), "utf8").toString("base64");

//   const fetchData = async (code) => {
//     const api = await (
//       await fetch(`https://apis.timestope.co.kr/v2/${code}`)
//     ).json();
//     setJSON(JSON.parse(toUTF8(api.data)));
//     setORG({
//       ...JSON.parse(toUTF8(api.data)),
//       latest: api.latest,
//       required: api.required,
//       en: api.en,
//     });
//     setLoading(false);
//   };

//   const onMenuClicked = async (e, { name }) => {
//     setItem(name);
//     await fetchData(name);
//   };

//   const country = [
//     "KO",
//     "EN",
//     "CN",
//     "ID",
//     "IN",
//     "IR",
//     "MM",
//     "NE",
//     "PH",
//     "PK",
//     "SP",
//     "TR",
//     "VN",
//   ];

//   const onChange = async (key, value, parent, data) => {
//     setJSON((prevState) => ({
//       ...prevState,
//       [key]: value,
//     }));
//     setRecent({ key, value });
//   };

//   const downloadJSON = () => {
//     const filename = `${activeItem}.json`;
//     const contentType = "application/json;charset=utf-8;";
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//       const blob = new Blob(
//         [decodeURIComponent(encodeURI(JSON.stringify(rJSON, null, "\t")))],
//         { type: contentType }
//       );
//       navigator.msSaveOrOpenBlob(blob, filename);
//     } else {
//       const a = document.createElement("a");
//       a.download = filename;
//       a.href = `data:${contentType},${encodeURIComponent(
//         JSON.stringify(rJSON, null, "\t")
//       )}`;
//       a.target = "_blank";
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     }
//   };

//   const openAccordion = (e, titleProps) => {
//     const { index } = titleProps;
//     const newIndex = activeIndex === index ? -1 : index;

//     setActiveIndex(newIndex);
//   };
//   const openAccordion2 = (e, titleProps) => {
//     const { index } = titleProps;
//     const newIndex = activeIndex2 === index ? -1 : index;

//     setActiveIndex2(newIndex);
//   };

//   const refreshPage = () => {
//     window.location.reload();
//   };

//   const postUpdates = async () => {
//     const { memo, password, nickName } = text;
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         password,
//         countryCode: activeItem,
//         memo,
//         nickName,
//         data: Buffer.from(JSON.stringify(rJSON), "utf8").toString("base64"),
//       }),
//     };
//     const apis = await (
//       await fetch(
//         "https://apis.timestope.co.kr/v2/updateTranslate",
//         requestOptions
//       )
//     ).json();
//     if (apis.success) {
//       alert(apis.message);
//       setsOpen(false);
//       refreshPage();
//     } else alert(`Save Translation Failed!!!\n${apis.message}`);
//   };

//   const pwChanges = (e, { name, value }) => {
//     const change = {
//       password: "password",
//       memo: "memo",
//       nickname: "nickName",
//       search: "search",
//     };
//     setText({
//       ...text,
//       [change[name]]: value,
//     });

//     setSearch({
//       isSearch: true,
//     });
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <SuiBox
//       // borderRadius="md"
//       // display="flex"
//       // justifyContent="center"
//       // alignItems="center"
//       >
//         <Card>
//           {loading ? (
//             <div style={{ margin: 100 }}>
//               <Header as="h1">Please click your country.</Header>
//               <Menu secondary>
//                 {country.map((x) => (
//                   <Menu.Item
//                     name={x}
//                     active={activeItem === x}
//                     onClick={onMenuClicked}
//                   />
//                 ))}
//               </Menu>
//               {/* <button onClick={async () => await fetchData("KO")} /> */}
//             </div>
//           ) : (
//             <div>
//               <Modal
//                 closeIcon
//                 open={sopen}
//                 trigger={
//                   <Button icon labelPosition="left">
//                     <Icon name="save" />
//                     Save Changes
//                   </Button>
//                 }
//                 onClose={() => setsOpen(false)}
//                 onOpen={() => setsOpen(true)}
//               >
//                 <Modal.Header>{`<< WARNING >>`}</Modal.Header>
//                 <Modal.Content>
//                   <p>Are You Sure to save changes?</p>
//                   <p>
//                     {`Please type Password which you've received from TimeStope
//                     TEAM.`}
//                   </p>
//                   <Input
//                     placeholder="Password"
//                     onChange={pwChanges}
//                     name="password"
//                   />
//                   <p>Please type your Name or NickName to save history.</p>
//                   <Input
//                     placeholder="NickName"
//                     onChange={pwChanges}
//                     name="nickname"
//                   />
//                   <p>Please type memo about you just edited.</p>
//                   <Input placeholder="Memo" onChange={pwChanges} name="memo" />
//                 </Modal.Content>
//                 <Modal.Actions>
//                   <Button onClick={postUpdates}>OK</Button>
//                 </Modal.Actions>
//               </Modal>
//               <Modal
//                 closeIcon
//                 open={open}
//                 trigger={
//                   <Button icon labelPosition="left">
//                     <Icon name="eye" />
//                     See Modifier
//                   </Button>
//                 }
//                 onClose={() => setOpen(false)}
//                 onOpen={() => setOpen(true)}
//               >
//                 <Modal.Header>{`TimeStope Translation ${activeItem} Changing History`}</Modal.Header>
//                 <Modal.Content>
//                   <Accordion
//                     // defaultActiveIndex={0}
//                     panels={original.latest.map((x) => ({
//                       key: "panel-1",
//                       title: `${x.text} By ${x.from} @ ${new Date(
//                         x.when
//                       ).toLocaleTimeString()}`,
//                       content: {
//                         content: x.difference.map((y, z) => {
//                           const { key, values } = y;
//                           const [After, Before] = values;
//                           return (
//                             <>
//                               <Accordion>
//                                 {/* {y.values.map((a, b) => ( */}
//                                 {/* <> */}
//                                 <Accordion.Title
//                                   active={activeIndex === z}
//                                   index={z}
//                                   onClick={openAccordion}
//                                 >
//                                   <Icon name="dropdown" />
//                                   {key}
//                                 </Accordion.Title>
//                                 <Accordion.Content active={activeIndex === z}>
//                                   {!Before ? (
//                                     <>
//                                       {!original[key] ? (
//                                         <Label>Deleted.</Label>
//                                       ) : (
//                                         <Label>Added.</Label>
//                                       )}
//                                     </>
//                                   ) : (
//                                     <div>
//                                       <Accordion
//                                         style={{ marginHorizontal: 10 }}
//                                       >
//                                         <Accordion.Title
//                                           index={0}
//                                           onClick={openAccordion2}
//                                         >
//                                           <Icon name="dropdown" />
//                                           Before
//                                         </Accordion.Title>
//                                         <Accordion.Content
//                                           active={activeIndex2 === 0}
//                                         >
//                                           <Label>{Before}</Label>
//                                         </Accordion.Content>

//                                         <Accordion.Title
//                                           index={1}
//                                           onClick={openAccordion2}
//                                         >
//                                           <Icon name="dropdown" />
//                                           After
//                                         </Accordion.Title>
//                                         <Accordion.Content
//                                           active={activeIndex2 === 1}
//                                         >
//                                           <Label>{After}</Label>
//                                         </Accordion.Content>
//                                       </Accordion>
//                                     </div>
//                                   )}
//                                 </Accordion.Content>
//                                 {/* </> */}
//                                 {/* ))} */}
//                               </Accordion>
//                               {/* <Header size="medium">{`At ${key}`}</Header>
//                               <>
//                                 {!Before ? (
//                                   <>
//                                     <text>no before</text>
//                                   </>
//                                 ) : (
//                                   <>
//                                     <Header size="tiny">{Before}</Header>
//                                     <Header size="tiny">⏬</Header>
//                                   </>
//                                 )}
//                                 <Header size="tiny">{After}</Header>
//                               </> */}
//                             </>
//                           );
//                         }),
//                       },
//                     }))}
//                     styled
//                   />
//                 </Modal.Content>
//                 <Modal.Actions>
//                   <Button onClick={() => setOpen(false)}>OK</Button>
//                 </Modal.Actions>
//               </Modal>
//               <Button icon labelPosition="left" onClick={downloadJSON}>
//                 <Icon name="download" />
//                 Download JSON
//               </Button>
//               <Input
//                 icon="search"
//                 placeholder="Search..."
//                 onChange={pwChanges}
//                 name="search"
//               />

//               {original.required.length === 0 ? undefined : (
//                 <Message
//                   style={{ margin: 10 }}
//                   error
//                   header={`There ${original.required.length === 1 ? "is" : "are"
//                     } ${original.required.length} things required to fix.`}
//                   list={original.required.map(
//                     (x) =>
//                       // `"${x.data}" is required to ${
//                       //   x.type === "new"
//                       //     ? "fix(cuz of new one)"
//                       //     : "maintain 'proper noun' ex) 'timestope', 'time'"
//                       // }`
//                       `"${Object.keys(x)[0]}" is required to maintain "${x.filterWord
//                       }" original: ${JSON.stringify(x[Object.keys(x)[0]])} (${x.times
//                       } times.)`
//                   )}
//                 />
//               )}
//               {recent.key === "TEST" ? undefined : (
//                 <Header
//                   as="h2"
//                   content="Recently Edited"
//                   subheader={`From "${recent.key}" To "${recent.value
//                     }" (default = ${original[recent.key]})`}
//                   style={{ margin: 10 }}
//                 />
//               )}
//               <JSONEditor
//                 data={
//                   search.isSearch
//                     ? Object.assign(
//                       {},
//                       ...Object.keys(rJSON)
//                         .filter((val) => {
//                           if (text.search === "") {
//                             return val;
//                           }
//                           if (
//                             val
//                               .toLowerCase()
//                               .includes(text.search.toLowerCase())
//                           ) {
//                             return val;
//                           }
//                         })
//                         .map((x, y) => ({ [x]: rJSON[x] }))
//                     )
//                     : rJSON
//                 }
//                 collapsible
//                 synchronizedCollapse
//                 view="dual"
//                 onChange={onChange}
//                 showRemoveButton={false}
//               />
//             </div>
//           )}
//           {/* <IframeResizer
//           log
//           src="https://chat.timestope.co.kr"
//           frameBorder={0}
//           style={{ width: "1px", minWidth: "100%", minHeight: "100%" }}
//         /> */}
//         </Card>
//       </SuiBox>
//       <Footer />
//     </DashboardLayout>
//   );
// };

const Check = () => {
  const [search, setSearch] = useState("");

  const srchnge = (e, { name, value }) => {
    setSearch(value);
  };

  const doSearch = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: search,
      }),
    };
    const { success, message, data } = await (
      await fetch("https://apis.timestope.co.kr/v2/linkData", requestOptions)
    ).json();
    alert(message);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox>
        <Card>
          <Message
            style={{ margin: 10 }}
            error
            header="Notice"
            list={[
              "위 기능은 공식적으로 지원하는 툴이 아닙니다.",
              "위 기능은 테스트 용도로만 사용해주세요.",
            ]}
          />
          <Input
            icon="search"
            placeholder="Enter TimeStope ID."
            onChange={srchnge}
          />
          <Button icon labelPosition="left" onClick={doSearch}>
            <Icon name="search" />
            Check!
          </Button>
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};
export default Check;
