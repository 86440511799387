/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the BuildByDevelopers
import styles from "layouts/dashboard/components/BuildByDevelopers/styles";
import { useState, useEffect } from "react";

import SuiButton from "components/SuiButton";
// Images
// import wavesWhite from "assets/images/shapes/waves-white.svg"; /Removed/
// import rocketWhite from "assets/images/illustrations/rocket-white.png"; /Removed/

const BuildByDevelopers = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [all, setAll] = useState(false);
  const cngAll = () => {
    setAll(!all);
  };

  const fetchData = async () => {
    const api = await (await fetch("https://apis.timestope.co.kr/v1/noticeData")).json();
    setData(api);
    setLoading(false);
  };

  const toComma = (str) => {
    return str.toLocaleString(navigator.language);
  };

  function DecryptCafe(Original) {
    const data = Original.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, "").replace(
      /(<([^>]+)><([^>]+)>)/gi,
      "</br>"
    ); //.replace('<!-- } SE-TEXT --><!-- SE-TEXT { -->​<!-- } SE-TEXT --><!-- SE-TEXT  { -->', '\n')

    const sp = data.split("<!-- SE-TEXT { -->");
    let add = "";
    for (let i = 0; i < sp.length; i++) {
      if (i != 0) {
        add += `${sp[i].split("<!-- } SE-TEXT -->\n")[0]}`;
        if (sp[i].split("<!-- } SE-TEXT -->\n").length != 0) {
          add += "</br></br>";
        }
      }
    }
    return add;
  }
  function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
  }

  const toMultiLine = (str) => {
    return str.split("</br>").map((i, key) => {
      return <p key={key}>{i}</p>;
    });
  };

  const toMultiLine2 = (str) => {
    return str.split("</br>").map((i, key) => {
      if (key > 5) {
        return;
      } else {
        return <p key={key}>{i}</p>;
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const classes = styles();

  return (
    <Card>
      <SuiBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex" flexDirection="column" height="100%">
              <SuiBox pt={1} mb={0.5}>
                <SuiTypography variant="body2" textColor="text" fontWeight="medium">
                  TimeStope Notice @ 네이버 카페
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="bold" gutterBottom>
                {loading ? "로딩중" : data.datas[0].title}
              </SuiTypography>
              <SuiBox mb={6}>
                <SuiTypography variant="body2" textColor="text">
                  {loading
                    ? "로딩중"
                    : all
                    ? toMultiLine(DecryptCafe(data.datas[0].Object.contentHtml))
                    : toMultiLine2(DecryptCafe(data.datas[0].Object.contentHtml))}
                </SuiTypography>
              </SuiBox>

              <SuiButton variant="gradient" buttonColor="dark" onClick={() => cngAll()}>
                <SuiTypography
                  component="a"
                  href="#"
                  variant="button"
                  textColor="text"
                  fontWeight="medium"
                  customClass={classes.buildByDevelopers_button}
                >
                  {all ? "조금 보기" : "자세히 보기"}
                  <Icon className="font-bold">arrow_forward</Icon>
                </SuiTypography>
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={5} className="ml-auto relative">
            <SuiBox
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              backgroundColor="info"
              borderRadius="lg"
              backgroundGradient
            >
              {/* <SuiBox
                component="img"
                src={'http://file.pleizz.com/nCafe.png'}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%" 
              /> */}
              <SuiBox
                component="img"
                src={"http://file.pleizz.com/nCafe.png"}
                alt="rocket"
                width="100%"
                pt={3}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
};

export default BuildByDevelopers;
