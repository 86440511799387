/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Overview page components
// import Header from "layouts/profile/components/Header";
import { JSONEditor } from "react-json-editor-viewer";
import React, { useState, useEffect } from "react";
import {
    Menu,
    Header,
    Button,
    Icon,
    Modal,
    Accordion,
    Message,
    Label,
    Input,
    Flag,
    Segment,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import VideoPlayer from "react-player";
import _ from "lodash";

const Edit = () => {
    const [x, setTranslate] = useState({});
    const [org, setOrg] = useState({});
    const [api, setApi] = useState({});
    const [nowEditing, setNowEditing] = useState("");
    const [open, setOpen] = useState(false);
    const [sopen, setsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndex2, setActiveIndex2] = useState(0);
    const [obj, setObj] = useState({});
    const [text, setText] = useState({
        memo: "",
        password: "",
        nickName: "",
        search: "",
        countryCode: ""
    });
    const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1)

    const keys = [
        'mainContents', 'purpose', 'basic', 'regard',
        'responsible', 'certificate', 'illegalAct', 'chapter_1',
        'article_1', 'article_1_1', 'article_2', 'article_2_1',
        'article_2_2', 'article_2_3', 'article_3', 'article_3_1',
        'chapter_2', 'article_4', 'article_4_1', 'article_4_2',
        'article_4_3', 'article_5', 'article_5_1', 'article_5_2',
        'article_5_3', 'article_5_4', 'article_6', 'article_6_1',
        'article_6_2', 'article_6_3', 'article_6_4', 'article_6_5',
        'article_6_6', 'article_6_7', 'article_7', 'article_7_1',
        'chapter_3', 'article_8', 'article_8_1', 'article_8_2',
        'article_8_3', 'article_9', 'article_9_1', 'article_9_2',
        'article_9_3', 'article_9_4', 'article_10', 'article_10_1',
        'article_10_2', 'article_10_3', 'chapter_4', 'article_11',
        'article_11_1', 'article_11_2', 'article_12', 'article_12_1',
        'article_12_2', 'article_13', 'article_13_1', 'article_13_2_1',
        'article_13_2_2', 'article_13_3', 'article_13_4', 'article_13_5',
        'article_13_6', 'article_13_7', 'chapter_5', 'article_14',
        'article_14_1', 'article_14_2', 'article_14_3', 'chapter_6',
        'article_15', 'article_15_1', 'article_16', 'article_16_1',
        'article_17', 'article_17_1', 'additional', 'from'
    ];
    const handleChange = (e) => {
        if (!e.target.files[0]) return;
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        const fileName = e.target.files[0].name;
        let json = fileName.split(".");
        json = json[json.length - 1];
        if (
            !fileName.endsWith(".json") &&
            !fileName.endsWith(".txt") &&
            !fileName.endsWith(".JSON")
        )
            return alert(`Non Supported File ${json}`);
        fileReader.onload = (e) => {
            if (!e.target.result) return alert("No File Selected!");
            try {
                let parsedJSON = JSON.parse(e.target.result);

                if (getType(parsedJSON) === "Array") parsedJSON = Object.assign({}, ...parsedJSON);
                const jKey = Object.keys(parsedJSON);
                keys.map(x => {
                    if (!jKey.includes(x)) throw new Error(`key "${x}" is not included at your json.`)
                    return;
                })
                jKey.map(x => {
                    if (!keys.includes(x)) throw new Error(`incorrect key "${x}" is included at your json.`)
                    return;
                })
                setObj(parsedJSON);
            } catch (e) {
                alert(`an error occured with uploading files!\n${e.message}`);
                refreshPage();
            }
        };
    };

    const fetchTranslate = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
        };
        const { success, message, data } = await await (
            await fetch(
                `https://apis.timestope.co.kr/v2/terms/${text.countryCode}`,
                requestOptions
            )
        ).json();
        if (!success) {
            alert(`an Error occured.\n${message}`);
            window.location.href = "/translate";
        }
        setApi(data);
        setTranslate(data.translate.original);
        setOrg(data.org);
    };
    const refreshPage = () => {
        window.location.reload();
    };
    const setEditingNull = () => {
        setNowEditing("");
    };

    const pwChanges = (e, { name, value }) => {
        const change = {
            password: "password",
            memo: "memo",
            nickname: "nickName",
            search: "search",
            countryCode: "countryCode"
        };
        setText({
            ...text,
            [change[name]]: value,
        });
    };

    const textChange = (e, { name, value }) => {
        setTranslate({
            ...x,
            [name]: value,
        });
        setNowEditing(name);
    };

    const b64Img =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAA6CAYAAADcKStOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKlJREFUeNrs0MEJgDAQRNFgBZaQElKKJdiRJViSLdiBdrAG9CCCJC6YrPgHBnLZ8BjnCNFFRELsYBG1yJ7RIkpM4G5QdXEJVB1cJqos7iGqDE6JkuPGW0QFUKBAgQIFChQoUKCyUd4Sqjm919hZ8Ucb+85ap9Xa2El06cGBAwcOHDhw4MCB+yXOW8S9u5gSVwb1EFcWlYmrg0rg6qJucDZQF1znvppNgAEA5o0eG6C7GJMAAAAASUVORK5CYII=";
    const htmlTerm = `<div style="color: #666">
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.mainContents
        }</div>
    <div>${x.purpose}</div>
    <div>${x.basic}</div>
    <div>${x.regard}</div>
    <div>${x.responsible}</div>
    <div>${x.certificate}</div>
    <div>${x.illegalAct}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.chapter_1
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_1
        }</div>
    <div>${x.article_1_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_2
        }</div>
    <div>${x.article_2_1
        }<a href="https://timestope.com">https://timestope.com</a>${x.article_2_2
        }</div>
    <div>${x.article_2_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_3
        }</div>
    <div>${x.article_3_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.chapter_2
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_4
        }</div>
    <div>${x.article_4_1}</div>
    <div>${x.article_4_2}</div>
    <div>${x.article_4_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_5
        }</div>
    <div>${x.article_5_1}</div>
    <div>${x.article_5_2}</div>
    <div>${x.article_5_3}</div>
    <div>${x.article_5_4}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_6
        }</div>
    <div>${x.article_6_1}</div>
    <div>${x.article_6_2}</div>
    <div>${x.article_6_3}</div>
    <div>${x.article_6_4}</div>
    <div>${x.article_6_5}</div>
    <div>${x.article_6_6}</div>
    <div>${x.article_6_7}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_7
        }</div>
    <div>${x.article_7_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.chapter_3
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_8
        }</div>
    <div>${x.article_8_1}</div>
    <div>${x.article_8_2}</div>
    <div>${x.article_8_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_9
        }</div>
    <div>${x.article_9_1}</div>
    <div>${x.article_9_2}</div>
    <div>${x.article_9_3}</div>
    <div>${x.article_9_4}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_10
        }</div>
    <div>${x.article_10_1}</div>
    <div>${x.article_10_2}</div>
    <div>${x.article_10_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.chapter_4
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_11
        }</div>
    <div>${x.article_11_1}</div>
    <div>${x.article_11_2}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_12
        }</div>
    <div>${x.article_12_1}</div>
    <div>${x.article_12_2}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_13
        }</div>
    <div>${x.article_13_1}</div>
    <div>${`${x.article_13_2_1}\n\t\t${x.article_13_2_2}`}</div>
    <div>${x.article_13_3}</div>
    <div>${x.article_13_4}</div>
    <div>${x.article_13_5}</div>
    <div>${x.article_13_6}</div>
    <div>${x.article_13_7}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.chapter_5
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_14
        }</div>
    <div>${x.article_14_1}</div>
    <div>${x.article_14_2}</div>
    <div>${x.article_14_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.chapter_6
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_15
        }</div>
    <div>${x.article_15_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_16
        }</div>
    <div>${x.article_16_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.article_17
        }</div>
    <div>${x.article_17_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.additional
        }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${x.from
        }</div>
  </div>`;

    const openAccordion = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };
    const openAccordion2 = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex2 === index ? -1 : index;

        setActiveIndex2(newIndex);
    };
    const downloadFile = async () => {
        alert(`${"`"}`);
        const exportJS = `const htmlTerm${text.countryCode.toUpperCase()} = ${"`"}\n\t${htmlTerm}\n\n${"`"};\n`;
        const filename = `term${`${text.countryCode.toUpperCase()}`}.js`;
        const contentType = "text/plain;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const blob = new Blob([decodeURIComponent(encodeURI(exportJS))], {
                type: contentType,
            });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement("a");
            a.download = filename;
            a.href = `data:${contentType},${encodeURIComponent(exportJS)}`;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const postUpdates = async () => {
        const { memo, password, nickName } = text;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                password,
                countryCode: text.countryCode,
                memo,
                nickName,
                data: Buffer.from(JSON.stringify(obj), "utf8").toString("base64"),
            }),
        };
        const apis = await (
            await fetch(
                `https://apis.timestope.co.kr/v2/terms/edit/${text.countryCode}`,
                requestOptions
            )
        ).json();
        if (apis.success) {
            alert(apis.message);
            setsOpen(false);
            refreshPage();
        } else alert(`Save Translation Failed!!!\n${apis.message}`);
    };

    return (
        <div>
            <DashboardLayout>
                {/* <DashboardNavbar /> */}
                <SuiBox>
                    <Card>
                        <Header
                            as="h3"
                            color="blue"
                            textAlign="left"
                            style={{ margin: 50 }}
                        >
                            Please Upload Translate (Only ".json" Supports.)
                        </Header>

                        {
                            !_.isEmpty(obj)
                                ?
                                (
                                    <div>
                                        <Modal
                                            closeIcon
                                            open={sopen}
                                            trigger={
                                                <Button icon labelPosition="left" style={{ margin: 30 }}>
                                                    <Icon name="save" />
                                                    Save Changes
                                                </Button>
                                            }
                                            onClose={() => setsOpen(false)}
                                            onOpen={() => setsOpen(true)}
                                        >
                                            <Modal.Header>{`<< WARNING >>`}</Modal.Header>
                                            <Modal.Content>
                                                <p>Are You Sure to save changes?</p>
                                                <p>
                                                    {`Please type Password which you've received from TimeStope
                    TEAM.`}
                                                </p>
                                                <Input
                                                    placeholder="Password"
                                                    onChange={pwChanges}
                                                    name="password"
                                                />
                                                <p>
                                                    Please type your CountryCode to save Changes.
                                                </p>
                                                <Input
                                                    placeholder="CountryCode"
                                                    onChange={pwChanges}
                                                    name="countryCode"
                                                />
                                                <p>
                                                    Please type your Name or NickName to save history.
                                                </p>
                                                <Input
                                                    placeholder="NickName"
                                                    onChange={pwChanges}
                                                    name="nickname"
                                                />
                                                <p>Please type memo about you just edited.</p>
                                                <Input
                                                    placeholder="Memo"
                                                    onChange={pwChanges}
                                                    name="memo"
                                                />
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button onClick={postUpdates}>OK</Button>
                                            </Modal.Actions>
                                        </Modal>
                                        <Header
                                            as="h3"
                                            color="red"
                                            textAlign="left"
                                            style={{ margin: 30, marginTop: 0 }}
                                        >
                                            {JSON.stringify(obj, null, 2)}
                                        </Header>
                                    </div>
                                )
                                :
                                <input type="file" onChange={handleChange} style={{ margin: 40 }} />
                        }

                    </Card>
                </SuiBox>
                {/* <Footer /> */}
            </DashboardLayout>
        </div>
    );
};
export default Edit;
const replaceAll = (str, searchStr, replaceStr) =>
    str.split(searchStr).join(replaceStr);
