/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import Table from './data/index.js'

// Custom styles for the Projects
import styles from "layouts/dashboard/components/Projects/styles";

// Data
import DATA from "layouts/dashboard/components/Projects/data";

function Projects() {
  const [menu, setMenu] = useState(null);
  const classes = styles();

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <SuiBox>
          <SuiTypography variant="h6" gutterBottom>
            카카오톡 봇 데이터
          </SuiTypography>
          <SuiBox display="flex" alignItems="center" lineHeight={0}>
            <Icon className="font-bold text-info">done</Icon>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              &nbsp;<strong>등록된</strong> 봇 데이터
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox color="text" px={2}>
          <Icon
            className="cursor-pointer font-bold"
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </SuiBox>
        {renderMenu}
      </SuiBox>
      <DATA />
    </Card>
  );
}

export default Projects;
