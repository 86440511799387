/* eslint-disable no-nested-ternary */
// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiProgress from "components/SuiProgress";

// Custom styles for the Projects
import styles from "layouts/dashboard/components/Projects/styles";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// Soft UI Dashboard Materail-UI example components
import Table from "examples/Table";

import React, { useEffect, useState, View, Text } from "react";
import { alertTitleClasses } from "@mui/material";

export default function data() {
  const classes = styles();

  const [BotData, setBot] = useState({
    loading: true,
    data: [
      {
        count: 0,
        req: "RequestExample",
        res: { type: "text", text: "textTest", base64: "b64Str" },
      },
    ],
    count: 0,
  });
  const botData = async () => {
    const api = await (
      await fetch("https://apis.timestope.co.kr/v1/botData")
    ).json();
    setBot({
      loading: false,
      data: api.datas,
      count: api.datas.map((x) => x.count).reduce((a, b) => a + b),
    });
  };

  useEffect(() => {
    botData();
  }, []);

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <SuiAvatar
          src={image}
          alt="name"
          size="xs"
          customClass={classes.projects_tableAvatar}
        />
      </Tooltip>
    ));

  const rows = BotData.data.map((x) => ({
    Q: <text>{x.req}</text> /* [logoXD, x.req], */,
    A: (
      <>
        {x.res.type === "text" ? (
          <text>{x.res.text}</text>
        ) : (
          <img
            width={50}
            height={50}
            src={`data:image/jpg;base64, ${x.res.base64}`}
            alt="s"
          />
        )}
      </>
    ),
    // <SuiBox display="flex" py={1}>
    //   {avatars([
    //     [team1, "Ryan Tompson"],
    //     [team2, "Romina Hadid"],
    //     [team3, "Alexander Smith"],
    //     [team4, "Jessica Doe"],
    //   ])}
    // </SuiBox>
    Used: (
      <SuiTypography variant="caption" textColor="text" fontWeight="medium">
        {x.count}
      </SuiTypography>
    ),
    Percentage: (
      <SuiBox width="8rem" textAlign="left">
        <SuiProgress
          value={parseInt((x.count / BotData.count) * 100, 10)}
          color="info"
          gradient
        />
      </SuiBox>
    ),
  }));

  const columns = [
    { name: "Q", align: "left" },
    { name: "A", align: "left" },
    { name: "Used", align: "center" },
    { name: "Percentage", align: "center" },
  ];

  return (
    <SuiBox customClass={classes.projects_table}>
      {BotData.loading ? (
        <SuiTypography>Loading</SuiTypography>
      ) : (
        <Table columns={columns} rows={rows} />
      )}
    </SuiBox>
  );
}
