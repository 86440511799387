/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Overview page components
// import Header from "layouts/profile/components/Header";
import { JSONEditor } from "react-json-editor-viewer";
import React, { useState, useEffect } from "react";
import {
  Menu,
  Header,
  Button,
  Icon,
  Modal,
  Accordion,
  Message,
  Label,
  Input,
  Flag,
  Segment,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import VideoPlayer from "react-player";
import _ from "lodash";

const Edit = () => {
  const { countryCode } = useParams();
  const [x, setTranslate] = useState({});
  const [org, setOrg] = useState({});
  const [api, setApi] = useState({});
  const [nowEditing, setNowEditing] = useState("");
  const [open, setOpen] = useState(false);
  const [sopen, setsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [text, setText] = useState({
    memo: "",
    password: "",
    nickName: "",
    search: "",
  });

  const fetchTranslate = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    const { success, message, data } = await await (
      await fetch(
        `https://apis.timestope.co.kr/v2/terms/${countryCode}`,
        requestOptions
      )
    ).json();
    if (!success) {
      alert(`an Error occured.\n${message}`);
      window.location.href = "/translate";
    }
    setApi(data);
    setTranslate(data.translate.original);
    setOrg(data.org);
  };
  const refreshPage = () => {
    window.location.reload();
  };
  const setEditingNull = () => {
    setNowEditing("");
  };

  const pwChanges = (e, { name, value }) => {
    const change = {
      password: "password",
      memo: "memo",
      nickname: "nickName",
      search: "search",
    };
    setText({
      ...text,
      [change[name]]: value,
    });
  };

  const textChange = (e, { name, value }) => {
    setTranslate({
      ...x,
      [name]: value,
    });
    setNowEditing(name);
  };
  useEffect(() => {
    fetchTranslate();
  }, []);
  const b64Img =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAA6CAYAAADcKStOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKlJREFUeNrs0MEJgDAQRNFgBZaQElKKJdiRJViSLdiBdrAG9CCCJC6YrPgHBnLZ8BjnCNFFRELsYBG1yJ7RIkpM4G5QdXEJVB1cJqos7iGqDE6JkuPGW0QFUKBAgQIFChQoUKCyUd4Sqjm919hZ8Ucb+85ap9Xa2El06cGBAwcOHDhw4MCB+yXOW8S9u5gSVwb1EFcWlYmrg0rg6qJucDZQF1znvppNgAEA5o0eG6C7GJMAAAAASUVORK5CYII=";
  const htmlTerm = `<div style="color: #666">
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.mainContents
    }</div>
    <div>${x.purpose}</div>
    <div>${x.basic}</div>
    <div>${x.regard}</div>
    <div>${x.responsible}</div>
    <div>${x.certificate}</div>
    <div>${x.illegalAct}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.chapter_1
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_1
    }</div>
    <div>${x.article_1_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_2
    }</div>
    <div>${
      x.article_2_1
    }<a href="https://timestope.com">https://timestope.com</a>${
    x.article_2_2
  }</div>
    <div>${x.article_2_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_3
    }</div>
    <div>${x.article_3_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.chapter_2
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_4
    }</div>
    <div>${x.article_4_1}</div>
    <div>${x.article_4_2}</div>
    <div>${x.article_4_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_5
    }</div>
    <div>${x.article_5_1}</div>
    <div>${x.article_5_2}</div>
    <div>${x.article_5_3}</div>
    <div>${x.article_5_4}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_6
    }</div>
    <div>${x.article_6_1}</div>
    <div>${x.article_6_2}</div>
    <div>${x.article_6_3}</div>
    <div>${x.article_6_4}</div>
    <div>${x.article_6_5}</div>
    <div>${x.article_6_6}</div>
    <div>${x.article_6_7}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_7
    }</div>
    <div>${x.article_7_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.chapter_3
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_8
    }</div>
    <div>${x.article_8_1}</div>
    <div>${x.article_8_2}</div>
    <div>${x.article_8_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_9
    }</div>
    <div>${x.article_9_1}</div>
    <div>${x.article_9_2}</div>
    <div>${x.article_9_3}</div>
    <div>${x.article_9_4}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_10
    }</div>
    <div>${x.article_10_1}</div>
    <div>${x.article_10_2}</div>
    <div>${x.article_10_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.chapter_4
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_11
    }</div>
    <div>${x.article_11_1}</div>
    <div>${x.article_11_2}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_12
    }</div>
    <div>${x.article_12_1}</div>
    <div>${x.article_12_2}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_13
    }</div>
    <div>${x.article_13_1}</div>
    <div>${`${x.article_13_2_1}\n\t\t${x.article_13_2_2}`}</div>
    <div>${x.article_13_3}</div>
    <div>${x.article_13_4}</div>
    <div>${x.article_13_5}</div>
    <div>${x.article_13_6}</div>
    <div>${x.article_13_7}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.chapter_5
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_14
    }</div>
    <div>${x.article_14_1}</div>
    <div>${x.article_14_2}</div>
    <div>${x.article_14_3}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.chapter_6
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_15
    }</div>
    <div>${x.article_15_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_16
    }</div>
    <div>${x.article_16_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.article_17
    }</div>
    <div>${x.article_17_1}</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.additional
    }</div>
    <div style="font-weight: bold;font-size: 16;color:#000;margin-top:10px">${
      x.from
    }</div>
  </div>`;

  const openAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };
  const openAccordion2 = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex2 === index ? -1 : index;

    setActiveIndex2(newIndex);
  };
  const downloadFile = async () => {
    alert(`${"`"}`);
    const exportJS = `const htmlTerm${countryCode.toUpperCase()} = ${"`"}\n\t${htmlTerm}\n\n${"`"};\n`;
    const filename = `term${`${countryCode.toUpperCase()}`}.js`;
    const contentType = "text/plain;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob([decodeURIComponent(encodeURI(exportJS))], {
        type: contentType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.download = filename;
      a.href = `data:${contentType},${encodeURIComponent(exportJS)}`;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const postUpdates = async () => {
    const { memo, password, nickName } = text;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password,
        countryCode,
        memo,
        nickName,
        data: Buffer.from(JSON.stringify(x), "utf8").toString("base64"),
      }),
    };
    const apis = await (
      await fetch(
        `https://apis.timestope.co.kr/v2/terms/edit/${countryCode}`,
        requestOptions
      )
    ).json();
    if (apis.success) {
      alert(apis.message);
      setsOpen(false);
      refreshPage();
    } else alert(`Save Translation Failed!!!\n${apis.message}`);
  };

  return (
    <div>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <SuiBox>
          <Card>
            <>
              <div>
                <Header as="h1" textAlign="center" style={{ marginTop: 30 }}>
                  {`You are Editing ${countryCode}'s Translation now.`}
                </Header>
                <Header
                  as="h5"
                  color="pink"
                  textAlign="right"
                  style={{ marginRight: 30 }}
                >
                  Thank you for supporting US (:
                </Header>
                <VideoPlayer
                  url="https://cdn.timestope.co.kr/Terms.mp4"
                  muted
                  playing
                  volume={0}
                  loop
                />

                <Header as="h5" textAlign="left" style={{ marginLeft: 30 }}>
                  : Terms of Use @ TimeStope
                </Header>
                {_.isEmpty(x) ? undefined : (
                  <div>
                    <Header
                      as="h5"
                      color="green"
                      textAlign="right"
                      style={{ marginRight: 30 }}
                    >
                      {`Last Edit TimeStamp: ${new Date(
                        api.recentTimestamp
                      ).toLocaleString()}`}
                    </Header>

                    <Button
                      icon
                      labelPosition="left"
                      onClick={downloadFile}
                      style={{ marginLeft: 30 }}
                    >
                      <Icon name="download" />
                      {`Download term${`${countryCode.toUpperCase()}`}.js`}
                    </Button>
                    <Modal
                      closeIcon
                      open={sopen}
                      trigger={
                        <Button icon labelPosition="left">
                          <Icon name="save" />
                          Save Changes
                        </Button>
                      }
                      onClose={() => setsOpen(false)}
                      onOpen={() => setsOpen(true)}
                    >
                      <Modal.Header>{`<< WARNING >>`}</Modal.Header>
                      <Modal.Content>
                        <p>Are You Sure to save changes?</p>
                        <p>
                          {`Please type Password which you've received from TimeStope
                    TEAM.`}
                        </p>
                        <Input
                          placeholder="Password"
                          onChange={pwChanges}
                          name="password"
                        />
                        <p>
                          Please type your Name or NickName to save history.
                        </p>
                        <Input
                          placeholder="NickName"
                          onChange={pwChanges}
                          name="nickname"
                        />
                        <p>Please type memo about you just edited.</p>
                        <Input
                          placeholder="Memo"
                          onChange={pwChanges}
                          name="memo"
                        />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={postUpdates}>OK</Button>
                      </Modal.Actions>
                    </Modal>
                    <Modal
                      closeIcon
                      open={open}
                      trigger={
                        <Button icon labelPosition="left">
                          <Icon name="eye" />
                          See Modifier
                        </Button>
                      }
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                    >
                      <Modal.Header>{`TimeStope Translation ${countryCode.toUpperCase()} Changing History`}</Modal.Header>
                      <Modal.Content>
                        {api?.changeLog?.length !== 0 ? (
                          <Accordion
                            // defaultActiveIndex={0}
                            panels={api?.changeLog?.map((x) => ({
                              key: "panel-1",
                              title: `${x.text} By ${x.from} @ ${new Date(
                                x.when
                              ).toLocaleTimeString()}`,
                              content: {
                                content: x.difference.map((y, z) => {
                                  const { key, values } = y;
                                  const [After, Before] = values;
                                  return (
                                    <>
                                      <Accordion>
                                        {/* {y.values.map((a, b) => ( */}
                                        {/* <> */}
                                        <Accordion.Title
                                          active={activeIndex === z}
                                          index={z}
                                          onClick={openAccordion}
                                        >
                                          <Icon name="dropdown" />
                                          {key}
                                        </Accordion.Title>
                                        <Accordion.Content
                                          active={activeIndex === z}
                                        >
                                          {!Before ? (
                                            <>
                                              {!org[key] ? (
                                                <Label>Deleted.</Label>
                                              ) : (
                                                <Label>Added.</Label>
                                              )}
                                            </>
                                          ) : (
                                            <div>
                                              <Accordion
                                                style={{ marginHorizontal: 10 }}
                                              >
                                                <Accordion.Title
                                                  index={0}
                                                  onClick={openAccordion2}
                                                >
                                                  <Icon name="dropdown" />
                                                  Before
                                                </Accordion.Title>
                                                <Accordion.Content
                                                  active={activeIndex2 === 0}
                                                >
                                                  <Label>{Before}</Label>
                                                </Accordion.Content>

                                                <Accordion.Title
                                                  index={1}
                                                  onClick={openAccordion2}
                                                >
                                                  <Icon name="dropdown" />
                                                  After
                                                </Accordion.Title>
                                                <Accordion.Content
                                                  active={activeIndex2 === 1}
                                                >
                                                  <Label>{After}</Label>
                                                </Accordion.Content>
                                              </Accordion>
                                            </div>
                                          )}
                                        </Accordion.Content>
                                        {/* </> */}
                                        {/* ))} */}
                                      </Accordion>
                                      {/* <Header size="medium">{`At ${key}`}</Header>
                              <>
                                {!Before ? (
                                  <>
                                    <text>no before</text>
                                  </>
                                ) : (
                                  <>
                                    <Header size="tiny">{Before}</Header>
                                    <Header size="tiny">⏬</Header>
                                  </>
                                )}
                                <Header size="tiny">{After}</Header>
                              </> */}
                                    </>
                                  );
                                }),
                              },
                            }))}
                            styled
                          />
                        ) : (
                          <Header
                            as="h3"
                            color="blue"
                            textAlign="left"
                            style={{ margin: 0 }}
                          >{`${countryCode.toUpperCase()} has no Edit History. Try to change something!`}</Header>
                        )}
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={() => setOpen(false)}>OK</Button>
                      </Modal.Actions>
                    </Modal>
                  </div>
                )}

                <Header
                  as="h3"
                  color="red"
                  textAlign="left"
                  style={{ margin: 30 }}
                >
                  Under is rendered translation. Please refer to it.
                </Header>

                <iframe
                  title="TimeStopeTerms"
                  log
                  srcDoc={
                    _.isEmpty(x)
                      ? `Loading`
                      : `
                <div style="background-color:#041725;">
                <div>
                  <img src="${b64Img}" alt="image" width="3%" height="3%" style="margin:30px; vertical-align:middle;" />
                  <span style="text-align: center;
                  color:white;
                  font-size: 1.7rem;" >Terms of Use</span>
                </div>
                  <div style="margin-left: 10px;">
                    ${replaceAll(
                      replaceAll(
                        htmlTerm,
                        "<div>",
                        '<div style="color: white;" >'
                      ),
                      "color:#000;",
                      "color:white;"
                    )}
                  </div>
                </div>`
                  }
                  frameBorder={0}
                  width="45%"
                  height="2000"
                  style={{ float: "left" }}
                />
                <Card>
                  {Object.keys(x).map((a) => (
                    <Input
                      value={x[a]}
                      label={a}
                      onChange={textChange}
                      name={a}
                      style={{ marginLeft: 10, marginRight: 10 }}
                    />
                  ))}
                </Card>
              </div>
            </>

            {_.isEmpty(nowEditing) ? undefined : (
              <Card
                style={{
                  position: "fixed",
                  minWidth: "40%",
                  margin: 40,
                }}
              >
                <div>
                  <Button icon onClick={setEditingNull} style={{ margin: 30 }}>
                    <Icon name="close" />
                  </Button>
                </div>
                <Header
                  as="h1"
                  textAlign="center"
                  style={{ margin: 0, marginTop: 30 }}
                >
                  {`"${nowEditing}" is now Editing.`}
                </Header>
                <Header
                  as="h3"
                  color="blue"
                  textAlign="left"
                  style={{ margin: 30, marginTop: 0 }}
                >
                  {org[nowEditing]}
                </Header>
                <Header
                  as="h3"
                  color="red"
                  textAlign="left"
                  style={{ margin: 30, marginTop: 0 }}
                >
                  {x[nowEditing]}
                </Header>
                <p style={{ textAlign: "left", margin: 15, marginTop: 0 }}>
                  color Blue is Original English Value and Red is Editing Value.
                </p>
              </Card>
            )}
          </Card>
        </SuiBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </div>
  );
};
export default Edit;
const replaceAll = (str, searchStr, replaceStr) =>
  str.split(searchStr).join(replaceStr);
