/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import React, { useEffect, useState, View, Text } from "react";

const Dashboard = () => {
  const [data, setData] = useState({ expect: gradientLineChartData });
  const [loading, setLoading] = useState(true);
  const [Graph, setGraph] = useState("expect");

  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const fetchData = async () => {
    const api = await (await fetch("https://apis.timestope.co.kr/v1/membersData")).json();
    setData(api);
    setLoading(false);
  };



  const toComma = (str) => str.toLocaleString(navigator.language);
  function TimeLandPrice() {
    const today = new Date();
    const timeValue = new Date(Date.parse("2021-05-15T00:00:00.000Z"));

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);

    return Math.floor(parseInt(betweenTimeDay, 10) + 1999);
  }

  useEffect(() => {
    fetchData();
    const interv = setInterval(fetchData, 30000);
    return () => clearInterval(interv);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "오늘의 가입자 수" }}
                count={!loading ? `${`${toComma(data.members.gup)}명`}` : ""}
                percentage={{
                  color: data?.members?.gper >= 100 ? "success" : "error",
                  text: `${!loading
                    ? `${data?.members?.gper >= 100
                      ? `+${data?.members?.gper - 100}`
                      : `-${100 - data?.members?.gper}`
                    }%`
                    : ""
                    }`,
                }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              {/* <MiniStatisticsCard
                title={{ text: "new clients" }}
                count="+3,462"
                percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: "emoji_events" }}
              /> */}
              <MiniStatisticsCard
                title={{ text: "전세계 가입자 수" }}
                count={!loading ? `${`${toComma(data.members.gnum)}명`}` : ""}
                percentage={{
                  color: data?.members?.gper >= 100 ? "success" : "error",
                  text: `${!loading ? `+ ${toComma(data?.members?.gup)}` : ""}`,
                }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              {/* <MiniStatisticsCard
                title={{ text: "sales" }}
                count="$103,430"
                percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              /> */}
              <MiniStatisticsCard
                title={{ text: "한국인 가입자 수" }}
                count={!loading ? `${`${toComma(data.members.knum)}명`}` : ""}
                percentage={{
                  color: data?.members?.kper >= 100 ? "success" : "error",
                  text: `${!loading ? `+ ${toComma(data?.members?.kup)}` : ""}`,
                }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "오늘의 타임랜드 가격" }}
                count={`${`${toComma(TimeLandPrice())} lTime`}`}
                percentage={{
                  color: "success",
                  text: `+${(100 - ((TimeLandPrice() - 1) / TimeLandPrice()) * 100).toFixed(3)}%`,
                }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    (<strong>+23%</strong>) than last week
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              {/* <GradientLineChart
                title={`${!loading ? data?.members?.title : "가입자 현황을 불러오고 있어요~!"}`}
                description={
                  <>
                    <text>sdf</text>
                  </>
                  // <SuiBox display="flex" alignItems="center">
                  //   <SuiBox
                  //     fontSize={size.lg}
                  //     color={data?.members?.kper >= 100 ? "success" : "error"}
                  //     mb={0.3}
                  //     mr={0.5}
                  //     lineHeight={0}
                  //   >
                  //     {loading ? (
                  //       <div>
                  //         <SuiTypography variant="button" textColor="text" fontWeight="medium">
                  //           아직 데이터를 받아오지 못했어요~!
                  //         </SuiTypography>
                  //       </div>
                  //     ) : (
                  //       <>
                  //         <SuiBox display="flex" alignItems="center">
                  //           <ButtonGroup
                  //             variant="outlined"
                  //             aria-label="outlined button group"
                  //             alignItems="center"
                  //           >
                  //             <Button
                  //               onClick={() => {
                  //                 setGraph("expect");
                  //               }}
                  //             >
                  //               총 가입자 수
                  //             </Button>
                  //             <Button
                  //               onClick={() => {
                  //                 setGraph("reg");
                  //               }}
                  //             >
                  //               일별 가입자 수
                  //             </Button>
                  //             <Button
                  //               onClick={() => {
                  //                 setGraph("percent");
                  //               }}
                  //             >
                  //               전일 대비 가입자 비율
                  //             </Button>
                  //           </ButtonGroup>
                  //         </SuiBox>
                  //         <SuiTypography variant="button" textColor="text" fontWeight="medium">
                  //           {`한국인:  ${data.members.kper >= 100
                  //             ? data.members.kper - 100
                  //             : 100 - data.members.kper
                  //             }%`}
                  //         </SuiTypography>

                  //         <Icon className="font-bold">{`arrow_${data.members.kper >= 100 ? "up" : "down"
                  //           }ward`}</Icon>

                  //         <SuiBox
                  //           fontSize={size.lg}
                  //           color={data.members.gper >= 100 ? "success" : "error"}
                  //           mb={0.3}
                  //           mr={0.5}
                  //           lineHeight={0}
                  //         />

                  //         <SuiTypography variant="button" textColor="text" fontWeight="medium">
                  //           {`전세계: ${data.members.gper >= 100
                  //             ? data.members.gper - 100
                  //             : 100 - data.members.gper
                  //             }%`}
                  //         </SuiTypography>

                  //         <Icon className="font-bold">{`arrow_${data.members.gper >= 100 ? "up" : "down"
                  //           }ward`}</Icon>
                  //       </>
                  //     )}
                  //   </SuiBox>
                  // </SuiBox>
                }
                height="20.25rem"
                chart={`${loading ? gradientLineChartData : data[`${Graph}`]}`}
              /> */}
            </Grid>
          </Grid>
        </SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Dashboard;
